<script>
  
  import { onMount, onDestroy } from "svelte";
  import { __getTranslatedText } from "@i18n/index.ts";

  
  export let lang = "es-CO";

  let images = ["/assets/hero/herobanner1.webp", "/assets/hero/herobanner2.webp"];

  const src = images[0];

  let activeIndex = 0;
  let interval;
  let intervaltime = 10000;

  function nextImage() {
    const nextIndex = (activeIndex + 1) % images.length;
    activeIndex = nextIndex;
    const container = document.querySelector(".hero-slide");
    container.style.opacity = 0;
    container.style.transform = "translateY(25px)";
    setTimeout(() => {
      container.style.backgroundImage = `url(${images[nextIndex]})`;
      container.style.opacity = 1;
      container.style.transform = "translateY(0)";
    }, 1000);
  }

  onMount(() => {
    interval = setInterval(nextImage, intervaltime);
  });

  onDestroy(() => {
    clearInterval(interval);
  });


  const translations = {
    "es-CO": {
      "Your ideas have power": "Tus ideas tienen poder",
      "Take them beyond the imaginable": "Llévalas más allá de lo imaginable",
      "It ensures an experienced technical": " Asegura un equipo técnico experimentado desde el inicio y reduce costos en su desarrollo.",
      "Learn more": "Conoce más"
    },
    "en": {
      "Your ideas have power": "Your ideas have power",
      "Take them beyond the imaginable": "Take them beyond the imaginable",
      "It ensures an experienced technical": "It ensures an experienced technical team from the beginning and reduces development costs.",
      "Learn more": "Get to know us"
    },
  };

  const __getText = __getTranslatedText(translations, lang);
</script>

<header class="hero pt-6">
  <div class="hero-info">
    <div class="hero-info-content">
      <div class="hero-title">
        <h1 class="wow fadeIn" data-wow-delay="0.3s">{__getText("Your ideas have power")}</h1>
        <h2 class="wow fadeIn" data-wow-delay="0.5s">{__getText("Take them beyond the imaginable")}</h2>
      </div>

      <div class="hero-detail">
        <p class="wow fadeIn" data-wow-delay="0.7s">
          {__getText("It ensures an experienced technical")}
        </p>
        <a href="/about" class="btn btn-outline-mg btn-xlg wow fadeIn" data-wow-delay="0.9s">{__getText("Learn more")}</a>

        <div class="arrow-indicator">
          <div class="arrow-icon">
            <div class="arrow arrow-first" />
            <div class="arrow arrow-second" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="hero-slider">
    <div class="hero-slide" style="background-image: url({src});" />
  </div>
</header>

<style>
  .hero-slider {
    display: flex;
    overflow: hidden;
    transform: translateX(0);
    transition: transform 0.3s ease-out;
    width: 100%;
    height: 100%;
  }

  .hero-slider .hero-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    user-select: none;
    width: 100%;
    height: 100%;
    transition: all 1s ease;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .hero {
    margin-bottom: 50px;
    position: relative;
    height: calc(100vh - 50px);
  }

  .hero:after {
    content: "";
    display: block;
    width: 81px;
    height: 84px;
    position: relative;
    bottom: 84px;
    left: 0px;
    background-color: #ec5512;
  }

  .hero-info {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: calc(100vh - 146px);
    padding: 0px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 9;
  }

  .hero-info-content {
    width: 50vw;
    display: flex;
    flex-direction: column;
  }

  .hero-info-content h1 {
    font-size: 50px;
    line-height: 60px;
    font-weight: 500;
    color: #e4e4e4;
  }

  .hero-info-content h2 {
    font-size: 50px;
    line-height: 60px;
    font-weight: 200;
    color: #e4e4e4;
  }

  .hero-info-content p {
    font-size: 30px;
    line-height: 40px;
    font-weight: 200;
    color: #e4e4e4;
    margin-bottom: 30px;
  }

  .hero-detail {
    position: relative;
  }

  .hero-slider {
    padding: 0px 103px;
  }

  /* arrow*/

  .arrow-indicator {
    width: 100%;
    bottom: 0;
    display: none;
  }

  .arrow-icon {
    height: 15px;
    position: relative;
    margin-top: 15px;
  }
  .arrow {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 10px;
    transform-origin: 50% 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .arrow-first {
    transform: translate3d(-50%, -50%, 0);
    animation: arrow-movement 2s ease-in-out infinite;
  }
  .arrow-second {
    animation: arrow-movement 2s 1s ease-in-out infinite;
  }

  .arrow:before,
  .arrow:after {
    background: #fff;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
  }

  .arrow:before {
    transform: rotate(45deg) translateX(-23%);
    transform-origin: top left;
  }

  .arrow:after {
    transform: rotate(-45deg) translateX(23%);
    transform-origin: top right;
  }

  @media (max-width: 1400px) { 

    .hero {
      height: 100vh;
    }
    .hero-info {
      height: 100vh;
    }

    .hero-info-content {
      width: 75vw;
    }

    .hero-info-content h1,
    .hero-info-content h2 {
      font-size: 40px;
    }
  }
 
  @media (max-width: 769px) { 
    .arrow-indicator {
      display: block;
    }

    .hero {
      margin-bottom: 50px;
      height: calc(100vh - 100px);
      position: relative;
      top: 0;
      margin-bottom: 30px;
    }

    .hero-info {
      width: auto;
      padding: 0px 30px;
      position: absolute;
      height: calc(100vh - 390px);
      z-index: 1;
      top: 150px;
    }

    .hero-info-content {
      width: auto;
      height: calc(100vh - 200px);
      justify-content: space-between;
    }

    .hero-info-content h1 {
      font-size: 30px;
      line-height: 30px;
      text-align: center;
    }

    .hero-info-content h2 {
      font-size: 30px;
      line-height: 30px;
      text-align: center;
    }

    .hero-info-content p {
      font-size: 20px;
      line-height: 22px;
      font-weight: 200;
      text-align: center;
    }

    .hero-detail .btn {
      font-size: 22px;
      line-height: 35px;
      font-weight: 400;
      width: calc(100% - 2px);
      --mg-btn-padding-x: 70px;
      --mg-btn-padding-y: 15px;
    }

    .hero-slider {
      padding: 0px;
      height: calc(100vh - 123px);
      position: absolute;
      top: 0;
      z-index: 0;
    }

    .hero-slider .hero-slide {
      background-size: cover;
      background-position: center;
    }

    .hero:after {
      width: 26px;
      bottom: 44px;
      position: absolute;
    }
  }

  @media (max-width: 927px) and (orientation: landscape) {
    .hero-info-content {
      width: 80vw;
    }
  }
</style>
